const formFields = [
  {
    key: "q_name",
    label: "姓名",
    type: "input",
  },
  {
    key: "q_loc",
    label: "位置",
    type: "input",
  },
  {
    key: "q_year",
    label: "年份",
    type: "input",
  },
  {
    key: "q_school",
    label: "学校",
    type: "input",
  },
  {
    key: "q_major",
    label: "专业",
    type: "input",
  },
  {
    key: "q_career",
    label: "职业",
    type: "select",
    options: [
      {
        value: "1",
        label: "1",
      },
      {
        value: "2",
        label: "2",
      },
      {
        value: "3",
        label: "3",
      },
      {
        value: "4",
        label: "4",
      },
      {
        value: "5",
        label: "5",
      },
    ],
  },
  {
    key: "q_job",
    label: "职位",
    type: "select",
    options: [
      {
        value: "1",
        label: "1",
      },
      {
        value: "2",
        label: "2",
      },
      {
        value: "3",
        label: "3",
      },
      {
        value: "4",
        label: "4",
      },
      {
        value: "5",
        label: "5",
      },
    ],
  },
];

export default formFields;
