import "./index.css";

import { createClient } from "@supabase/supabase-js";

import { SUPABASE_URL, SUPABASE_KEY } from "../../config";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Spin,
} from "antd";

import MenuLayout from "../../components/Menu";
import { LoadingOutlined } from "@ant-design/icons";

import formFields from "./formFields";
import Loading from "../../components/Loading";

const { Meta } = Card;

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

const PAGE_SIZE = 20;

const initialQueryParams = {
  ...formFields.reduce((acc, item) => {
    acc[item.key] = null;
    return acc;
  }, {}),
  page: 1,
};

const Search = () => {
  const navigate = useNavigate();
  const [alumniList, setAlumniList] = useState([]);
  const queryParams = useRef(initialQueryParams);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
  });
  const [loading, setLoading] = useState(false);
  const [expandedContact, setExpandedContact] = useState(null);

  const handlePaginationChange = (newPageValue) => {
    queryParams.current = {
      ...queryParams.current,
      page: newPageValue,
    };
    setPagination((prev) => {
      return {
        ...prev,
        current: newPageValue,
      };
    });
  };

  useEffect(() => {
    console.log(111, queryParams.current);
    query();
  }, [queryParams.current]);

  const handleFinishForm = (formValues) => {
    const transformedFormValues = Object.entries(formValues).reduce(
      (acc, [key, value]) => {
        acc[key] = value === undefined ? null : value;
        return acc;
      },
      {}
    ); // set all undefined value to null

    queryParams.current = {
      ...transformedFormValues,
      page: 1,
    };
    setPagination((prev) => {
      return {
        ...prev,
        current: 1,
      };
    });
  };

  const handleClearForm = () => {
    form.resetFields();
    form.submit();
  };

  async function query() {
    setLoading(true);
    const { data, error } = await supabase.rpc("query_filter", {
      ...queryParams.current,
      qsize: PAGE_SIZE,
    });

    console.log(data, error);

    setAlumniList(data?.data || []);
    setPagination((prev) => {
      return {
        ...prev,
        total: data.total,
      };
    });
    setLoading(false);
  }

  const checkUserStatus = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (!user) {
      navigate("/login");
    }
  };

  const handleClickCard = (item) => {
    setExpandedContact(item);
  };

  useEffect(() => {
    checkUserStatus();
  }, []);

  return (
    <MenuLayout>
      <Form
        form={form}
        layout="inline"
        labelAlign="left"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        style={{
          maxWidth: 1200,
          marginLeft: 20,
        }}
        onFinish={handleFinishForm}
      >
        {formFields.map((item) => {
          if (item.type === "input") {
            return (
              <Form.Item label={item.label} key={item.key} name={item.key}>
                <Input className="form-item" />
              </Form.Item>
            );
          }

          if (item.type === "select" && item.options) {
            return (
              <Form.Item
                label={item.label}
                key={item.key}
                name={item.key}
                style={{ width: "220px", marginRight: 0 }}
              >
                <Select
                  className="form-item form-select"
                  options={item.options}
                  allowClear
                />
              </Form.Item>
            );
          }

          return <></>;
        })}
        <div className="form-function-line">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={handleClearForm}>清空</Button>
          </Form.Item>
        </div>
      </Form>
      <Modal
        title={expandedContact?.name_cn}
        open={expandedContact}
        footer={null}
        onCancel={() => {
          setExpandedContact(null);
        }}
      >
        <p>UI待施工：</p>
        <pre>{JSON.stringify(expandedContact, null, 2)}</pre>
      </Modal>
      <Spin tip="加载中..." indicator={Loading} spinning={loading}>
        <div className="alumni-list">
          {alumniList.map((item) => {
            return (
              <Card
                bordered={false}
                className="alumni-card"
                onClick={() => {
                  handleClickCard(item);
                }}
              >
                <Meta
                  title={item.name_cn}
                  description={`${item.schoolm_start_time}级 初中${item.schoolm_class}班 高中${item.schoolh_class}班`}
                />
                <Divider />
                <p>
                  <span className="tag">大学</span>
                  {item.school1} {item.school1_major}
                </p>
                <p>
                  <span className="tag">工作</span>
                  {item.employer} {item.job_desc}
                </p>
              </Card>
            );
          })}
        </div>
      </Spin>
      <Pagination
        current={pagination.current}
        total={pagination.total}
        pageSize={PAGE_SIZE}
        onChange={handlePaginationChange}
        className="pagination"
      />
    </MenuLayout>
  );
};

export default Search;
