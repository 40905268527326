import "./index.css";
import dayjs from 'dayjs';

import { createClient } from "@supabase/supabase-js";

import { SUPABASE_URL, SUPABASE_KEY } from "../../config";
import { useNavigate } from "react-router-dom";

import MenuLayout from "../../components/Menu";
import { useEffect, useRef, useState } from "react";

import { contactPreferences, educationHistory, formFields } from "./formFields";
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Spin,
  Switch,
} from "antd";
import Loading from "../../components/Loading";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

const selectOptions = [
  {
    label: "全部",
    value: 1,
  },
  {
    label: "同届同学",
    value: 2,
  },
  {
    label: "同班同学",
    value: 3,
  },
  {
    label: "不公开",
    value: 0,
  },
];
const yearTypeColumns = new Set(
  educationHistory
    .filter((item) => item.type === "year")
    .map((item) => item.key)
);

const MyProfile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    checkUserStatus();
    query_self();
  }, []);

  const checkUserStatus = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (!user) {
      navigate("/login");
    }
  };

  const handleFinishForm = async (formValues) => {
    setSubmitLoading(true);
    const { name_cn, ...remainingFormValues } = formValues;

    for (let key in remainingFormValues) {
      if (yearTypeColumns.has(key) && remainingFormValues[key]) {
        remainingFormValues[key] = remainingFormValues[key].year();
      }
    }

    const { data, error } = await supabase.rpc(
      "update_self",
      remainingFormValues
    );

    console.log(data, error);
    setSubmitLoading(false);
  };

  const processData = (data) => {
    for (let key in data) {
      if (yearTypeColumns.has(key) && data[key]) {
        data[key] = dayjs(data[key].toString(), "YYYY");
      }
    }

    console.log(data)
    return data;
  };

  const query_self = async () => {
    setLoading(true);
    const { data, error } = await supabase.rpc("query_self", {});

    console.log(data, error);

    form.setFieldsValue(processData(data?.data?.[0] || {}));
    setLoading(false);
  };

  const getFormItem = (item) => {
    if (item.type === "input") {
      return (
        <Form.Item label={item.label} key={item.key} name={item.key}>
          <Input disabled={item.disabled} />
        </Form.Item>
      );
    }
    if (item.type === "select" && item.options) {
      return (
        <Form.Item label={item.label} key={item.key} name={item.key}>
          <Select className="form-select" options={item.options} allowClear />
        </Form.Item>
      );
    }
    if (item.type === "year") {
      return (
        <Form.Item label={item.label} key={item.key} name={item.key}>
          <DatePicker picker="year" />
        </Form.Item>
      );
    }
  };

  return (
    <MenuLayout>
      <Spin
        tip="加载中..."
        indicator={Loading}
        spinning={loading || submitLoading}
      >
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 500,
            marginLeft: 20,
          }}
          onFinish={handleFinishForm}
        >
          <div className="section-title">基础信息：</div>
          {formFields.map((item) => {
            return (
              <div className="form-item-container">
                <div className="form-item-input-element">
                  {getFormItem(item)}
                </div>
                <div className="form-item-privacy-control-element">
                  {item.privacy_control_key && (
                    <Form.Item
                      key={item.privacy_control_key}
                      name={item.privacy_control_key}
                      label="公开范围："
                      labelCol={{ span: 12 }}
                    >
                      <Select style={{ width: 140 }} options={selectOptions} />
                    </Form.Item>
                  )}
                </div>
              </div>
            );
          })}
          <Divider />
          <div className="section-title">教育背景：</div>
          {educationHistory.map((item) => {
            return getFormItem(item);
          })}
          <Divider />
          <div className="section-title">可以在以下方面联系我：</div>
          {contactPreferences.map((item) => {
            return (
              <Form.Item
                name={item.key}
                valuePropName="checked"
                wrapperCol={{ offset: 0, span: 16 }}
              >
                <Checkbox>{item.label}</Checkbox>
              </Form.Item>
            );
          })}
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              提交更新
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </MenuLayout>
  );
};

export default MyProfile;
