import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import "./index.css";

const { Header, Content } = Layout;

const tabs = [
  {
    key: "/search",
    label: "搜索",
  },
  {
    key: "/my-profile",
    label: "个人资料",
  },
];

const MenuLayout = ({ children }) => {
  const { pathname: path } = useLocation();
  const navigate = useNavigate();

  const handleMenuClick = ({ key }) => {
    navigate(key);
  };

  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          background: "white",
        }}
      >
        <div className="menu-logo">RDFZ Alumni</div>
        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={path}
          items={tabs}
          onClick={handleMenuClick}
        />
      </Header>
      <Layout>
        <Content
          style={{
            margin: "24px 16px 0",
          }}
        >
          <div
            style={{
              padding: 10,
              minHeight: 360,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MenuLayout;
