const formFields = [
  {
    key: "name_cn",
    label: "姓名",
    type: "input",
    disabled: true,
  },
  {
    key: "pub_email",
    label: "邮箱",
    type: "input",
    privacy_control_key: "p_pub_email",
  },
  {
    key: "phone",
    label: "电话",
    type: "input",
    privacy_control_key: "p_phone",
  },
  {
    key: "wechat_id",
    label: "微信ID",
    type: "input",
    privacy_control_key: "p_wechat_id",
  },
  {
    key: "linkedin",
    label: "领英",
    type: "input",
    privacy_control_key: "p_linkedin",
  },
  {
    key: "homepage_link",
    label: "主页链接",
    type: "input",
    privacy_control_key: "p_homepage_link",
  },
  {
    key: "other_contact",
    label: "其他联系方式",
    type: "input",
    privacy_control_key: "p_other_contact",
  },
  {
    key: "loc_country",
    label: "国家",
    type: "input",
    privacy_control_key: "p_loc",
  },
  {
    key: "loc_city",
    label: "城市",
    type: "input",
    privacy_control_key: "p_loc",
  },
  {
    key: "cp_career",
    label: "职业",
    type: "checkbox",
  },
  {
    key: "cp_academic",
    label: "学术",
    type: "checkbox",
  },
  {
    key: "cp_local_gather",
    label: "当地聚会",
    type: "checkbox",
  },
  {
    key: "cp_dating",
    label: "约会",
    type: "checkbox",
  },
  {
    key: "school1",
    label: "学校1",
    type: "input",
    privacy_control_key: "p_school1",
  },
  {
    key: "school2",
    label: "学校2",
    type: "input",
    privacy_control_key: "p_school2",
  },
  {
    key: "school3",
    label: "学校3",
    type: "input",
    privacy_control_key: "p_school3",
  },
  {
    key: "school4",
    label: "学校4",
    type: "input",
    privacy_control_key: "p_school4",
  },
  {
    key: "employer",
    label: "雇主",
    type: "input",
    privacy_control_key: "p_career",
  },
  {
    key: "job_field",
    label: "工作领域",
    type: "select",
    options: [
      {
        value: "1",
        label: "1",
      },
      {
        value: "2",
        label: "2",
      },
      {
        value: "3",
        label: "3",
      },
      {
        value: "4",
        label: "4",
      },
      {
        value: "5",
        label: "5",
      },
    ],
    privacy_control_key: "p_career",
  },
  {
    key: "job_desc",
    label: "工作描述",
    type: "input",
    privacy_control_key: "p_career",
  },
  {
    key: "misc_text",
    label: "其他文本",
    type: "input",
  },
];

const educationHistory = [
  {
    key: "school1_end_time",
    label: "学校1结束时间",
    type: "year",
  },
  {
    key: "school1_level",
    label: "学校1等级",
    type: "select",
    options: [
      {
        value: 1,
        label: "本科",
      },
      {
        value: 2,
        label: "硕士",
      },
      {
        value: 3,
        label: "博士",
      },
    ],
  },
  {
    key: "school1_major",
    label: "学校1专业",
    type: "input",
  },
  {
    key: "school1_start_time",
    label: "学校1开始时间",
    type: "year",
  },
  {
    key: "school2_end_time",
    label: "学校2结束时间",
    type: "year",
  },
  {
    key: "school2_level",
    label: "学校2等级",
    type: "select",
    options: [
      {
        value: 1,
        label: "本科",
      },
      {
        value: 2,
        label: "硕士",
      },
      {
        value: 3,
        label: "博士",
      },
    ],
  },
  {
    key: "school2_major",
    label: "学校2专业",
    type: "input",
  },
  {
    key: "school2_start_time",
    label: "学校2开始时间",
    type: "year",
  },
  {
    key: "school3_end_time",
    label: "学校3结束时间",
    type: "year",
  },
  {
    key: "school3_level",
    label: "学校3等级",
    type: "select",
    options: [
      {
        value: 1,
        label: "本科",
      },
      {
        value: 2,
        label: "硕士",
      },
      {
        value: 3,
        label: "博士",
      },
    ],
  },
  {
    key: "school3_major",
    label: "学校3专业",
    type: "input",
  },
  {
    key: "school3_start_time",
    label: "学校3开始时间",
    type: "year",
  },
  {
    key: "school4_end_time",
    label: "学校4结束时间",
    type: "year",
  },
  {
    key: "school4_level",
    label: "学校4等级",
    type: "select",
    options: [
      {
        value: 1,
        label: "本科",
      },
      {
        value: 2,
        label: "硕士",
      },
      {
        value: 3,
        label: "博士",
      },
    ],
  },
  {
    key: "school4_major",
    label: "学校4专业",
    type: "input",
  },
  {
    key: "school4_start_time",
    label: "学校4开始时间",
    type: "year",
  },
];

const contactPreferences = [
  {
    key: "cp_academic",
    label: "学术沟通（科研，选校，选专业等）",
    type: "input",
  },
  {
    key: "cp_career",
    label: "职业沟通（行业资源，工作内推等）",
    type: "input",
  },
  {
    key: "cp_local_gather",
    label: "参加/组织当地校友聚会",
    type: "input",
  },
  {
    key: "cp_dating",
    label: "个人情感",
    type: "input",
  },
];

export { formFields, educationHistory, contactPreferences };
