import "./index.css";

import { createClient } from "@supabase/supabase-js";

import { SUPABASE_URL, SUPABASE_KEY } from "../../config";
import { useEffect, useState } from "react";
import { Button, Input, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);
const CAPTCHA_COOLDOWN_TIME = 30;

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchaCountdown, setCaptchaCountdown] = useState(0);
  const [getCaptchaLoading, setGetCaptchaLoading] = useState(false);
  const [loginLoading, setLoginCaptchaLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
  };

  async function getCaptcha() {
    setGetCaptchaLoading(true);
    const { data, error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        shouldCreateUser: false,
      },
    });

    if (error?.name === "AuthApiError") {
      message.error("您的邮箱不在白名单内，请检查您的邮箱或联系管理员");

      setGetCaptchaLoading(false);
      return;
    }

    message.success("验证码已发送");
    setCaptchaCountdown(CAPTCHA_COOLDOWN_TIME);
    const countdownInterval = setInterval(() => {
      setCaptchaCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(countdownInterval); // 当countdown为0时，清除倒计时
          return 0;
        }

        return prevCountdown - 1;
      });
    }, 1000);

    setGetCaptchaLoading(false);
  }

  async function login() {
    setLoginCaptchaLoading(true);
    const { data, error } = await supabase.auth.verifyOtp({
      email,
      token: captcha,
      type: "email",
    });

    if (error) {
      message.error("登陆失败");
    } else {
      localStorage.setItem("auth", JSON.stringify(data));
    }

    setLoginCaptchaLoading(false);
  }

  const checkUserStatus = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      navigate("/search");
    }
  };

  useEffect(() => {
    checkUserStatus();
  }, []);

  return (
    <div className="main">
      <div className="login-form">
        <div className="logo">RDFZ Alumni</div>
        <Input
          placeholder="填写您的邮箱"
          prefix={<MailOutlined />}
          value={email}
          onChange={handleEmailChange}
        />
        <div className="otp-container">
          <Input
            placeholder="填写验证码"
            value={captcha}
            onChange={handleCaptchaChange}
          />
          <Button
            className="otp-container-button"
            onClick={getCaptcha}
            loading={getCaptchaLoading}
            disabled={captchaCountdown > 0}
          >
            {captchaCountdown > 0
              ? `${captchaCountdown}秒后重新获取`
              : "获取验证码"}
          </Button>
        </div>

        <Button
          className="submit-button"
          // disabled
          type="primary"
          onClick={login}
          loading={loginLoading}
        >
          登录
        </Button>
      </div>
    </div>
  );
}

export default Login;
